const BILLING_CURRENCY_ENUM = {
	USD: 'USD',
	CAD: 'CAD',
	AUD: 'AUD',
	NZD: 'NZD',
	GBP: 'GBP',
	EUR: 'EUR',
};

function getLocale({ currencyCode }) {
	if (currencyCode === BILLING_CURRENCY_ENUM.GBP) {
		return 'en-GB';
	}
	if (currencyCode === BILLING_CURRENCY_ENUM.EUR) {
		return 'de-DE';
	}
	return 'en-US';
}

export function getFormattedCurrency({ amount, billingCurrency }) {
	return new Intl.NumberFormat(getLocale({ currencyCode: billingCurrency }), {
		style: 'currency',
		currency: billingCurrency,
	}).format(amount);
}
