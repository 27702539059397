import React, { ReactElement, useEffect, useState } from 'react';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { Flex } from '@bamboohr/fabric';
import { RenderNavDropdown, RenderNavList, RenderSideSubNavigation } from './components/index';
import { getAccessLevelNav } from './utils';
import { NavData } from './utils/types';
/* @startCleanup encore */
import { useStyles } from './styles';
/* @endCleanup encore */

export function AccessLevelSubNav({initialActiveTab = ''}): ReactElement {
	const [activeTab, setActiveTab] = useState(initialActiveTab);
	const [canAddLevels, setCanAddLevels] = useState(false);
	const [levelTypes, setLevelTypes] = useState([]);
	/* @startCleanup encore */
	const styles = useStyles();
	/* @endCleanup encore */

	const getData = () => {
		getAccessLevelNav().then((data: NavData) => {
			setCanAddLevels(data.canAddLevels);
			setLevelTypes(data.levelTypes);
		});
	};

	const handleTabUpdate = (value) => {
		setActiveTab(value);
		const allAccessLevels = levelTypes.reduce((res, current) => {
			if (Array.isArray(current)) {
				return [...res, ...current];
			}
			if (current.levels) {
				return [...res, ...current.levels];
			}
			return [...res, current];
		}, []);
		const selectedOption = allAccessLevels.find((option) => option.id == value || option.name === value);
		window.history.pushState({}, null, selectedOption.href);
	};

	useEffect(() => {
		getData();
	}, []);
	return (
		<Flex
			alignItems='baseline'
			// @startCleanup encore
			className={styles.flexNavContainer}
			// @endCleanup encore
		>
			{ifFeature('encore', <RenderSideSubNavigation activeTab={activeTab} canAddLevels={canAddLevels} levelTypes={levelTypes} setActiveTab={handleTabUpdate} />, <RenderNavList activeTab={activeTab} levelTypes={levelTypes} setActiveTab={handleTabUpdate} />)}
			{ifFeature('encore', undefined, canAddLevels && <RenderNavDropdown />)}
		</Flex>
	);
}
