import { render } from 'base/wrapped-render';
import { CancellationModal, SadToGoBox } from 'in-app-cancellation.mod';
import { PoMicroFrontend } from 'micro-frontend.react';
import TrialActivationModal from 'time-tracking/modals/trial-activation-modal';
import { ProjectTracking } from '../project-tracking';
import { EmployeeSettings } from './employees/employee-settings';
import { Table } from './employees/table';
import { SetupChecklist } from './setup-checklist.react';


/** Renders the entire settings page in Po */
export function initSettings(data?: Record<string, unknown>): void {
	render(
		<PoMicroFrontend
			{...data}
			renderCancellationModal={(props) => <CancellationModal {...props} />}
			renderEmployeeSettings={(props) => <EmployeeSettings {...props} />}
			renderEmployeeTable={(props) => <Table {...props} />}
			renderProjectSettings={(props) => <ProjectTracking {...props} />}
			renderSadToGoBox={(props) => <SadToGoBox {...props} isAddon={true} />}
			renderSetupChecklist={(props) => <SetupChecklist {...props} />}
			renderTrialActivationModal={(props) => <TrialActivationModal {...props} />}
			route='/time-tracking/settings/all'
		/>,
		document.getElementById('time-tracking-settings-root')
	);
}
