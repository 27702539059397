import {
	Box,
	BodyText,
	Button,
	Flex,
	Headline,
	LayoutBox,
	makeStyles,
	StyledBox,
	TextButton,
	Typography,
} from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { ReactElement } from 'react';

interface UpsellBannerContentProps {
	dismissBanner: () => void;
	onClick: () => void;
}

/* @startCleanup encore */
const useStyles = makeStyles(({ spacing }) => ({
	body: {
		minWidth: '625px',
		width: '65%',
	},
	description: {
		marginBottom: spacing(2),
	},
	title: {
		fontWeight: 'bold',
		marginTop: 0,
	},
}));
/* @endCleanup encore */

export const UpsellBannerContent = (props: UpsellBannerContentProps): ReactElement => {
	const { dismissBanner, onClick } = props;
	const styles = useStyles();

	const headline = $.__('Streamline Your Benefits Management');
	const description = $.__(
		"Discover how BambooHR Benefits Administration can save time for both you and your employees during benefit selection and enrollment. Upgrade now to automate enrollment, ensure data accuracy, and simplify compliance."
	);
	const primaryLabel = $.__('Learn More');
	const cancelLabel = $.__('Not right now');
	const buttons = (
		<>
			<Button onClick={onClick} type='button'>
				{primaryLabel}
			</Button>
			<TextButton onClick={dismissBanner} type="button">{cancelLabel}</TextButton>
		</>
	);

	/* @startCleanup encore */
	const jadeContent = (
		<Box className={styles.body}>
			<Typography className={styles.title} variant='h4'>
				{headline}
			</Typography>
			<Typography className={styles.description}>
				{description}
			</Typography>
			<Box>
				{buttons}
			</Box>
		</Box>
	);
	/* @endCleanup encore */

	return ifFeature(
		'encore',
		<LayoutBox>
			<LayoutBox marginBottom={2} marginTop="0px">
				<StyledBox marginTop="0px">
					<Headline color="neutral-strong" size="small">{headline}</Headline>
				</StyledBox>
				<BodyText>{description}</BodyText>
			</LayoutBox>
			<Flex alignItems="center" gap={2}>
				{buttons}
			</Flex>
		</LayoutBox>,
		jadeContent,
	);
};
