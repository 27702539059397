import { BlankState, LayoutBox } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { Clipboard60x72 } from '@bamboohr/grim';

export function ProjectTrackingBlankState({ addProjectBtn }) {
	return(
		<LayoutBox paddingTop={6}>
			<BlankState
				actions={[addProjectBtn]}
				icon={ifFeature('encore', 'bs-clipboard', <Clipboard60x72 />)}
				subtitle={$.__('Let your people track their work on projects and tasks.')}
				title={$.__('Need to get specific?')}
			/>
		</LayoutBox>
	);
}
