import { Button, Typography } from '@bamboohr/fabric';
import { showSlidedown } from 'Alerts.mod';
import { redirect } from 'BambooHR.util';
import React, { ReactElement, useState } from 'react';
import Upsell from 'Upsell';
import { DemoRequest, OnDemoRequest } from '../';
import { benefitsAdmin } from '../../../app-configs/upsell';
import api from '../../../app-services/api';
import { serializeDemoRequest } from '../serializers';

interface BenefitsAdminUpsellProps {
	demoRequest: DemoRequest;
	isCompanyInTrial: boolean;
	onDemoRequest: OnDemoRequest;
}

export const BenefitsAdminUpsell = ({ demoRequest, isCompanyInTrial, onDemoRequest }: BenefitsAdminUpsellProps): ReactElement => {
	// See example of demo state shape below in defaultProps
	const [demo, setDemo] = useState(serializeDemoRequest(demoRequest));
	const [isRequestPending, setIsRequestPending] = useState(false);

	const {
		demoButton,
		disclaimer,
		headline,
		summary,
		summaryItems,
		title,
		trialButton,
		trialDisclaimer,
		trialHeadline,
		trialSummary,
		trialSummary2,
		trialSummaryItems,
		trialTitle,
		videoId,
	} = benefitsAdmin;

	const handleEnableBenefitsAdminRequest = () => {
		setIsRequestPending(true);
		api.benefitsAdmin.enable
			.post()
			.then(() => {
				window.location.reload();
			})
			.catch(() => {
				window.errorFallBack();
			});
	};

	const handleUpsellRequest = () => {
		setIsRequestPending(true);
		api.expansion.benefitsAdminDemoRequest
			.post()
			.then(({ data }) => {
				// TODO: reimplement when Upsells are moved to Po
				// const serializedData = serializeDemoRequest(data);
				// setDemo(serializedData);
				// onDemoRequest(serializedData);
				// showSlidedown($.__("Thanks for letting us know! We'll be getting in touch soon to schedule your demo."), 'success');

				//* Temporary solution until Upsells are moved to Po
				redirect(
					window.location.href,
					$.__("Thanks for letting us know! We'll be getting in touch soon to schedule your demo."),
					'success'
				);
			})
			.catch(() => {
				showSlidedown($.__('Oops... something went wrong. Please try again.'), 'error');
			});
		setIsRequestPending(false);
	};

	const getActionButtons = () => {
		if (isCompanyInTrial) {
			return [
				// eslint-disable-next-line react/jsx-key
				<Button
					noSpacing={true}
					onClick={handleEnableBenefitsAdminRequest}
					processing={isRequestPending}
					size='large'
					type='button'
					width={100}
				>
					{trialButton}
				</Button>,
			];
		}
		return [
			// eslint-disable-next-line react/jsx-key
			<Button
				data-bi-id='settings-benefits-admin-request-demo-button'
				disabled={demo.isRequested}
				noSpacing={true}
				onClick={handleUpsellRequest}
				processing={isRequestPending}
				size='large'
				type='button'
				width={100}
			>
				{demoButton}
			</Button>,
		];
	};

	return (
		<Upsell>
			<Upsell.Title>{isCompanyInTrial ? trialTitle : title}</Upsell.Title>
			<Upsell.Video title={$.__('Benefits Administration Video')} videoId={videoId} />
			<Upsell.Content
				actions={getActionButtons()}
				request={{
					demoRequestedBy: demo.requestedBy,
					demoRequestedDate: demo.requestDate,
					isDemoRequested: demo.isRequested,
				}}
				requestPending={isRequestPending}
				sideNavMessage={
					isCompanyInTrial && (
						<Typography color={(theme) => theme.palette.gray[700]} textAlign='center' variant='small'>
							*{trialDisclaimer}
						</Typography>
					)
				}
			>
				<h4>{isCompanyInTrial ? trialHeadline : headline}</h4>
				<p>{isCompanyInTrial ? trialSummary : summary}</p>
				{isCompanyInTrial && <p>{trialSummary2}</p>}
				<ul>
					{(isCompanyInTrial ? trialSummaryItems : summaryItems).map((item, index) => (
						// eslint-disable-next-line react/no-array-index-key
						<li key={`benefits-admin-item-${index}`}>{item}</li>
					))}
				</ul>
				<Typography color={(theme) => theme.palette.gray[800]} variant='small'>
					{disclaimer}
				</Typography>
			</Upsell.Content>
		</Upsell>
	);
};
