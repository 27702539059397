import { ReactElement, useState } from 'react';
import { Box, Flex, IconV2, LayoutBox, makeStyles, StyledBox } from '@bamboohr/fabric';
import { HiBubble24x24 } from '@bamboohr/grim';
import { ifFeature } from '@bamboohr/utils/lib/feature';

import { UpsellBannerContent } from './upsell-banner-content';
import { UpsellBannerVideoPreview } from './upsell-banner-video-preview';
import { BenefitsAdminUpsellModal } from '../';

interface BenefitsAdminUpsellBannerProps {
	dismissBanner: () => void;
	isCompanyInTrial: boolean;
	onDemoRequest: (data) => void;
}

/* @startCleanup encore */
const useStyles = makeStyles(({ palette, spacing }) => ({
	root: {
		backgroundColor: palette.gray[100],
		borderBottom: `solid 1px ${palette.gray[300]}`,
		borderTop: `solid 1px ${palette.gray[300]}`,
		marginBottom: spacing(3),
		padding: `${spacing(3)} ${spacing(2)}`,
		width: `calc(100% - 32px)`,
	},

	content: {
		display: 'inline-flex',
		paddingRight: spacing(3),
	},
	icon: {
		fill: palette.primary.main,
		marginRight: spacing(2),
		marginTop: '11px',
	},
}));
/* @endCleanup encore */

export function BenefitsAdminUpsellBanner(props: BenefitsAdminUpsellBannerProps): ReactElement {
	const { dismissBanner, isCompanyInTrial, onDemoRequest } = props;
	const [isUpsellModalOpen, setIsUpsellModalOpen] = useState(false);
	const styles = useStyles();

	const handleOpenUpsellModal = () => {
		setIsUpsellModalOpen(true);
	};

	const handleCloseUpsellModal = () => {
		setIsUpsellModalOpen(false);
	};

	/* @startCleanup encore */
	const jadeBanner = (
		<Flex className={styles.root}>
			<Box className={styles.icon}>
				<HiBubble24x24 />
			</Box>
			<Box className={styles.content} justifyContent='space-between'>
				<UpsellBannerContent dismissBanner={dismissBanner} onClick={handleOpenUpsellModal} />
				<UpsellBannerVideoPreview onClick={handleOpenUpsellModal} />
			</Box>
			<BenefitsAdminUpsellModal
				isCompanyInTrial={isCompanyInTrial}
				isOpen={isUpsellModalOpen}
				onClose={handleCloseUpsellModal}
				onDemoRequest={onDemoRequest}
			/>
		</Flex>
	);
	/* @endCleanup encore */

	return ifFeature(
		'encore',
		<StyledBox
			backgroundColor="neutral-medium"
			borderRadius="small"
			boxShadow="small"
			marginBottom={3}
			paddingX={2}
			paddingY={3}
		>
			<Flex alignItems="start" gap={2}>
				<LayoutBox marginTop={0.5}>
					<IconV2 color="primary-strong" name="message-heart-solid" size={24} />
				</LayoutBox>
				<Flex justifyContent="space-between" gap={2}>
					<UpsellBannerContent dismissBanner={dismissBanner} onClick={handleOpenUpsellModal} />
					<UpsellBannerVideoPreview onClick={handleOpenUpsellModal} />
				</Flex>
			</Flex>
			<BenefitsAdminUpsellModal
				isCompanyInTrial={isCompanyInTrial}
				isOpen={isUpsellModalOpen}
				onClose={handleCloseUpsellModal}
				onDemoRequest={onDemoRequest}
			/>
		</StyledBox>,
		jadeBanner,
	);
}
