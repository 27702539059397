import { createLogger } from '@utils/dev-logger';

import './styles.styl';

window.globalSettingsLogger = createLogger('GlobalSettings');

import './apps';
import './access-levels';
import './nav';
import './page';

import './alerts';
import './pto';

import './account';

import './users';
import './tasklists';
import './tasklists/onboarding';
import './training';
import './benefits';
import './performance';
import './enps';
import './employee-wellbeing';
import './employee-community';
import './permissions';
import './employee-fields';
import './time-tracking';
import './directory';
import './workflows';
import './payroll';
import './total-rewards';
import './compensation';
import './custom-fields';
import './project-tracking';

import 'timepicker';

import './logo-and-color';
import './holiday';
