import React from 'react';
import {
	BlankState,
	LayoutBox,
	Table as FabricTable,
	makeStyles,
} from '@bamboohr/fabric';
// @startCleanup encore
import { PartyHat93x120 } from '@bamboohr/grim';
// @endCleanup encore
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { getColumns } from './helpers';

// @startCleanup encore
const useStyles = makeStyles({
	blankTableWrap: {
		backgroundColor: '#fff',
		opacity: '0.5',
		position: 'absolute',
		inset: '0',
		height: '45px',
		zIndex: '1',
	},
});
// @endCleanup encore

export const InvoiceHistoryTable = ({ billingCurrency, onRowSelect, rows }) => {
	// @startCleanup encore
	const classes = useStyles();
	// @endCleanup encore

	return (
		<LayoutBox position="relative">
			{ifFeature(
				'encore',
				undefined,
				rows.length === 0 && <div className={classes.blankTableWrap}></div>,
			)}
			{ifFeature(
				'encore',
				rows.length > 0 && (
					<FabricTable
						caption={$.__('Invoice History')}
						columns={getColumns({ billingCurrency })}
						initialSort={{
							columnIndex: 1,
							isAsc: false,
						}}
						onRowSelect={onRowSelect}
						rowKey={({ id }) => id}
						rows={rows}
					/>
				),
				<FabricTable
					caption={$.__('Invoice History')}
					columns={getColumns({ billingCurrency })}
					initialSort={{
						columnIndex: 1,
						isAsc: false,
					}}
					onRowSelect={onRowSelect}
					rowKey={({ id }) => id}
					rows={rows}
				/>,
			)}
			{rows.length === 0 && (
				<LayoutBox marginY={9}>
					<BlankState
						icon={ifFeature('encore', 'bs-party-hat', <PartyHat93x120 />)}
						subtitle={$.__('Please change your filter and try again.')}
						title={$.__(
							"Party's Over! There are no invoices for this time frame.",
						)}
					/>
				</LayoutBox>
			)}
		</LayoutBox>
	);
};
