/* eslint-disable react/jsx-key */
import { Message } from '@bamboohr/utils/lib/message';

export const benefitsAdmin = {
	demoButton: $.__('Get My Benefits Administration Demo'),
	disclaimer: $.__('Please note that Benefits Administration is only available for US-based employees.'),
	headline: $.__('Simplified, accurate, and fully integrated benefits management.'),
	summary: $.__('From onboarding to open enrollment, our benefits administration tool simplifies the process for you and your employees—all in one familiar system.'),
	summaryItems: [
		<Message
			text={$.__(
				'**Easy Employee Elections:** Employees can quickly review and select their benefits online alongside their other HR tasks in BambooHR, and get their elections approved in seconds.'
			)}
		/>,
		<Message
			text={$.__(
				"**Seamless Data Sync with Payroll:** Sync benefit deductions automatically with BambooHR Payroll, eliminating the need for manual updates or third-party tools. Plus, ask how you can get a discount when you bundle Payroll and Benefits Administration!"
			)}
		/>,
		<Message
			text={$.__(
				'**Time-Saving Data Transfers:** Export employee data to brokers or carriers with just a few clicks, using customizable PDF forms or automatic connections to top benefit carriers.'
			)}
		/>,
		<Message
			text={$.__(
				'**Accuracy and Peace of Mind:** Reduce errors and manual work with synced systems that ensure accurate benefits data and deductions.'
			)}
		/>,
	],
	title: $.__('Easier Benefits Administration with BambooHR'),
	trialButton: $.__('Enable Benefits Administration'),
	trialDisclaimer: $.__(
		'Benefits Administration is an add-on solution offered for an additional cost. Please contact your Account Executive with any questions.'
	),
	trialHeadline: $.__('Enable Benefits Administration In Your Trial Account'),
	trialSummary: $.__(
		`From onboarding elections to annual open enrollment, BambooHR® Benefits Administration for US-based employees provides a benefits experience that's easy to see and easier for everyone to manage—all the way through the employee life cycle.`
	),
	trialSummary2: $.__(`Some of the benefits you'll experience with Benefits Administration include:`),
	trialSummaryItems: [
		<Message
			text={$.__(
				'**Easy Employee Elections:** Employees can quickly review and select their benefits online alongside their other HR tasks in BambooHR, and get their elections approved in seconds.'
			)}
		/>,
		<Message
			text={$.__(
				"**Seamless Data Sync with Payroll:** Sync benefit deductions automatically with BambooHR Payroll, eliminating the need for manual updates or third-party tools. Plus, ask how you can get a discount when you bundle Payroll and Benefits Administration!"
			)}
		/>,
		<Message
			text={$.__(
				'**Time-Saving Data Transfers:** Export employee data to brokers or carriers with just a few clicks, using customizable PDF forms or automatic connections to top benefit carriers.'
			)}
		/>,
		<Message
			text={$.__(
				'**Accuracy and Peace of Mind:** Reduce errors and manual work with synced systems that ensure accurate benefits data and deductions.'
			)}
		/>,
	],
	trialTitle: $.__('Faster, friendlier, more accurate benefits management.'),
	videoId: 'crkdybeljr',
};

export const payroll = {
	detail: $.__(
		"Save time, reduce errors, and virtually eliminate double entry by adding payroll directly to your BambooHR account. When you make updates in BambooHR, data automatically flows into payroll to keep everything up-to-date. And with comprehensive payroll reporting, superior customer support, and full-service tax filing included, you'll be surprised how simple payroll really can be."
	),
};

export const payrollItems = [
	<Message
		text={$.__(
			'**Ease and Accuracy:** Reduce errors and pay your employees with ease by keeping your HR and payroll data in one place.',
		)}
	/>,
	<Message
		text={$.__(
			'**Empower Employees:** Easy employee access to paystubs, W-2s, and tax documents—whether on desktop or mobile.',
		)}
	/>,
	<Message
		text={$.__(
			'**Exceptional Support:** Enjoy the same award-winning customer support you already love from a team of payroll experts.',
		)}
	/>,
	<Message
		text={$.__(
			'**Effortless Compliance:** We handle federal, state, and local tax filings on your behalf, helping you stay compliant and reducing the stress that typically comes with end-of-year filings.',
		)}
	/>,
	<Message
		text={$.__(
			'**Unified Data and Reporting:** Comprehensive payroll reporting and easy access to audit trails enabling better analysis and decision-making.',
		)}
	/>,
	<Message
		text={$.__(
			"**Top-Tier Security:** Feel confident with BambooHR's robust data security measures, ensuring your sensitive payroll data is protected at all times.",
		)}
	/>,
];

export const performance = {
	detail: $.__(
		'Performance reviews are broken. At least, they were. We fixed a few things like huge time commitments, weak results, and infrequency. Then we added meaningful feedback and better ways to measure performance and engagement. The result? Innovative (dare we say, enjoyable?) performance management.'
	),
};

export const performanceItems = [
	<Message text={$.__(`**Create performance assessments** that get to the heart of your employees' unique needs.`)} />,
	<Message text={$.__('**Eliminate paper processes and free up time** with automated reminders.')} />,
	<Message
		text={$.__(
			'**Leverage real-time feedback from peers, managers, or direct reports** to resolve concerns and keep employees engaged.'
		)}
	/>,
	<Message
		text={$.__('**Use targeted performance reporting** to make strategic, data-driven decisions and improve employee growth.')}
	/>,
	<Message
		text={$.__(
			`**Have employees and managers set, track, and align on goals** to drive individual employee development and collectively move your organization's mission forward.`
		)}
	/>,
];

export const timeTracking = {
	detail: $.__(
		'Time Tracking adds hourly time management and reporting to the award-winning BambooHR experience, helping you reduce payroll errors and compliance risks with self-service time entry, approvals, automated reminders, automatic overtime calculations, and accurate payroll reporting.'
	),
};

export const timeTrackingItems = [
	$.__('Give your employees the option to clock in on the web or the mobile app'),
	$.__('Record and track employee time worked, total time spent on projects, or both'),
	$.__('See where your employees clock in and out with Geolocation'),
	$.__('Review and edit timesheets and project hours through four standard reports'),
	$.__(
		'Reduce payroll errors and compliance risks with automated approval reminders, automatic overtime calculations, and accurate payroll reporting'
	),
];
