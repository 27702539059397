import ReactDOM from 'react-dom';
import axios, { AxiosResponse } from 'axios';
import { render } from 'base/wrapped-render';
import { PoMicroFrontend } from 'micro-frontend.react';

type EsignatureUserActionEventData = {
	employeeId: number | null;
	message: string;
	workflowInstanceId: number | null;
	esignatureInstanceId: number;
};

export const openESignatureSigning = (esignatureInstanceId, isViewOnly = false) => {
	const rootId = `esignature-signing-root-${esignatureInstanceId}`;
	const root = document.getElementById(rootId);
	if (root) {
		render(
			<PoMicroFrontend
				esignatureInstanceId={esignatureInstanceId}
				interactionMode={isViewOnly ? 'view_only' : 'signing'}
				onClose={() => {
					const rootElement = document.getElementById(rootId);
					ReactDOM.unmountComponentAtNode(rootElement);
				}}
				onFinishLater={(data: EsignatureUserActionEventData) => {
					const { workflowInstanceId } = data;
					let url = `/esignatures/workflow_instance/${workflowInstanceId}/task_event_data/?type=save`;
					if (window.SESSION_USER.isOnboardingUser) {
						url = '/nhp' + url;
					}
					if (window.SESSION_USER.isOfferLetterUser) {
						url = '/offer_letter' + url;
					}
					setMessage(data.message, 'success');
					axios.get(url).then((res: AxiosResponse) => {
						window.BambooHR.publish('esignatures:signing:finishLater', {...data, ...res.data});
					}).catch((error) => {
						console.error('Esignature onFinishLater error: ', error);
					});
				}}
				onSigningSave={(data: EsignatureUserActionEventData) => {
					const { workflowInstanceId } = data;
					let url = `/esignatures/workflow_instance/${workflowInstanceId}/task_event_data/?type=complete`;
					if (window.SESSION_USER.isOnboardingUser) {
						url = '/nhp' + url;
					}
					if (window.SESSION_USER.isOfferLetterUser) {
						url = '/offer_letter' + url;
					}
					//grab the fileCard that was just completed, disable it until it's refreshed
					const fileCardElement = `#workflow_instance_${workflowInstanceId} > .FileCard`;
					if (fileCardElement && fileCardElement.length) {
						$(fileCardElement).addClass('FileCard--disabled');
						$(fileCardElement).attr("onclick", "").unbind("click");
					}
					setMessage(data.message, 'success');
					axios.get(url).then((res: AxiosResponse) => {
						window.BambooHR.publish('esignatures:signing:save', {...data, ...res.data});
					}).catch((error) => {
						console.error('Esignature onSigningSave error: ', error);
					});
				}}
				route='/e-signature-signing'
			/>,
			root
		);
	}
};
