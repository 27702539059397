import {
	useState,
	useEffect,
	useContext,
	useCallback,
	ReactElement,
} from 'react';
import { chunk } from 'lodash';
import Ajax from '@utils/ajax';
import {
	ProgressiveLoader,
	Table,
	defaultSorter,
	TableColumn,
	BodyText,
	LayoutBox,
	Flex,
	Link,
} from '@bamboohr/fabric';
import { Message } from '@bamboohr/utils/lib/message';
import { ActiveMissingInfoContext } from '../context/active-missing-info-provider';
import { UpdateMissingInfoBtn } from './update-missing-info-btn';

import { ActiveMissingInfoContextData, TableDataItem } from '../types';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export function ActiveMissingInfoTable(): ReactElement {
	const [rowLength, setRowLength] = useState<number>(0);
	const [paginatedRows, setPaginatedRows] = useState<TableDataItem[][]>([]);
	const [rowPageIndex, setRowPageIndex] = useState<number>(0);
	const { tableData, isNewOnboardingEnabled } = useContext(
		ActiveMissingInfoContext,
	) as ActiveMissingInfoContextData;

	const updateLooping = useCallback((employeeIdArray: TableDataItem[]) => {
		const newArray = employeeIdArray.map(
			(element: TableDataItem) => element.employeeId,
		);
		Ajax.put(`/settings/payroll/payroll_checklist/active_employees/looping`, {
			employeeIds: newArray,
		});
	}, []);

	useEffect(() => {
		setRowLength(tableData.length);
		setPaginatedRows(paginateRows(tableData));
		updateLooping(tableData);
	}, [tableData, updateLooping]);

	const handlePageClick = useCallback(({ nextPage }) => {
		setRowPageIndex(nextPage - 1);
	}, []);
	const handleTableSort = useCallback(
		(column, sortBy, sortAsc) => {
			const sortedTableData = defaultSorter(tableData, sortBy, sortAsc);
			setRowPageIndex(0);
			updateLooping(sortedTableData);
			setPaginatedRows(paginateRows(sortedTableData));
		},
		[tableData, updateLooping],
	);

	if (!rowLength) {
		return null;
	}

	return (
		<Flex flexDirection="column" marginBottom={8} gap={2}>
			<BodyText color="neutral-medium" justify="start">
				<Message
					params={[rowLength]}
					text={$._n(
						'We are missing required information for **{1} Employee**.',
						'We are missing required information for **{1} Employees**.',
						rowLength,
					)}
				/>
			</BodyText>
			<Table
				caption={$.__('Employees with missing info')}
				columns={getColumns(isNewOnboardingEnabled)}
				onSort={handleTableSort}
				rowKey={(row: TableDataItem) => row.employeeId}
				rows={paginatedRows[rowPageIndex]}
			/>
			{rowLength > 100 && (
				<LayoutBox marginTop={ifFeature('encore', 2, undefined)}>
					<ProgressiveLoader
						currentPage={rowPageIndex + 1}
						onPageChange={handlePageClick}
						pageSize={100}
						pagination={true}
						totalItemsCount={rowLength}
					/>
				</LayoutBox>
			)}
		</Flex>
	);
}

// Utilities...
function getColumns(
	isNewOnboardingEnabled: boolean,
): TableColumn<TableDataItem>[] {
	return [
		{
			header: $.__('Name'),
			cell: (row: TableDataItem): ReactElement => (
				<Link href={`/employees/employee.php?id=${row.employeeId}`}>
					{row.firstName} {row.lastName}
				</Link>
			),
			verticalAlign: true,
			sortBy: (row: TableDataItem): string => {
				return row.lastName ? row.lastName.toLowerCase() : row.lastName;
			},
		},
		{
			header: $.__('Job Title'),
			cell: (row: TableDataItem): string => row.jobTitle,
			verticalAlign: true,
			sortBy: (row: TableDataItem): string => row.jobTitle,
		},
		{
			header: $.__('Employee Info'),
			cell: (row: TableDataItem): ReactElement => (
				<BodyText color="warning-strong">
					{row.missingEmployeeInfo > 0 &&
						$.__n(
							'%1$s Missing Field',
							'%1$s Missing Fields',
							row.missingEmployeeInfo,
						)}
				</BodyText>
			),
			verticalAlign: true,
			sortBy: (row: TableDataItem): number => row.missingEmployeeInfo,
		},
		...(isNewOnboardingEnabled
			? []
			: [
					{
						header: $.__('Taxes'),
						cell: (row: TableDataItem): ReactElement => (
							<BodyText color="warning-strong">
								{row.missingTaxInfo > 0 &&
									$.__n(
										'%1$s Missing Field',
										'%1$s Missing Fields',
										row.missingTaxInfo,
									)}
							</BodyText>
						),
						verticalAlign: true,
						sortBy: (row: TableDataItem): number => row.missingTaxInfo,
					},
					{
						header: $.__('Direct Deposit'),
						cell: (row: TableDataItem): ReactElement => (
							<BodyText color="warning-strong">
								{row.isMissingDirectDepositAccount && $.__('Missing Account')}
							</BodyText>
						),
						verticalAlign: true,
						sortBy: (row: TableDataItem): string =>
							row.isMissingDirectDepositAccount.toString(),
					},
			  ]),
		{
			align: 'right',
			headerAriaLabel: $.__('Update Missing Info Button'),
			cell: (row: TableDataItem) => {
				const {
					employeeId,
					jobTitle,
					missingEmployeeInfo,
					missingTaxInfo,
					isMissingDirectDepositAccount,
					firstName,
					lastName,
				} = row;

				return (
					<UpdateMissingInfoBtn
						employeeId={employeeId}
						firstName={firstName}
						jobTitle={jobTitle}
						lastName={lastName}
						missingDirectDeposit={isMissingDirectDepositAccount}
						missingTaxFields={missingTaxInfo}
						totalMissingFields={missingTaxInfo + missingEmployeeInfo}
					/>
				);
			},
			verticalAlign: true,
			showOnHover: true,
		},
	];
}

function paginateRows(rows: TableDataItem[]): TableDataItem[][] {
	return chunk(rows, 100);
}
